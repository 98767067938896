import msg from '../getErrorMsg';

export default (input, validationRules, context) => {
  const { value } = input;

  const { error } = validationRules;

  if (!isNaN(Number(value)) && Number(value) === 0) {
    return msg(context, error);
  }

  return false;
};
