import React, { Component } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import { rollbar } from '@common/rollbar';

import RouteProcessor from './routeProcessor';
import { tProvide } from 'TRANSLATION';
import defaults from 'DEFAULTS';
import api from 'API';
import { responseError } from 'FIELDS';
import { Modal } from 'antd';

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      images: {},
    };

    this.getData = this.getData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.responseError = responseError.bind(this, this);
  }

  async componentDidMount() {
    await this.getData();
  }

  async getData() {
    try {
      this.setState({ loading: true });

      const resp = await axios(api.users.profile.banner_info().AXIOS('GET'));
      this.setState({ images: resp.data });
    } catch (error) {
      this.responseError(error);
    } finally {
      if (this._mounted) {
        this.setState({ loading: false });
      }
    }
  }

  toggleModal(visible = !this.state.visible) {
    this.setState({
      visible: visible,
    });
  }

  componentDidCatch(error) {
    false && rollbar.error(error);
  }

  render() {
    const { AUTHORIZED, VERIFIED, userToken } = this.props;

    const { images, visible } = this.state;
    const initialRedirect = `/${defaults.variables.account_types[1]}`;

    return (
      <BrowserRouter>
        {images?.is_visible && images?.top_banner?.image && (
          <img
            src={images?.top_banner?.image}
            alt="top-logo"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        )}

        <Switch>
          <Redirect exact from="/" to={initialRedirect} />

          <Route
            path="/:userType"
            render={(match) => (
              <RouteProcessor AUTHORIZED={AUTHORIZED} VERIFIED={VERIFIED} match={match.match} />
            )}
          />
        </Switch>

        <Modal
          visible={images?.is_visible && !userToken?.token ? visible : false}
          destroyOnClose={true}
          maskClosable={false}
          footer={null}
          onCancel={() => this.toggleModal()}
        >
          {images?.is_visible && images?.right_banner?.image && (
            <img
              src={images?.is_visible && images?.right_banner?.image}
              alt="right-logo"
              style={{
                marginTop: 30,
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          )}
        </Modal>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userToken: state.userToken,
    AUTHORIZED: state.userToken.token !== null,
    VERIFIED: state.userToken.status,
  };
};

export default tProvide(connect(mapStateToProps)(IndexPage));

IndexPage.propTypes = {
  userToken: PropTypes.object,
  AUTHORIZED: PropTypes.bool.isRequired,
  VERIFIED: PropTypes.bool.isRequired,
};

IndexPage.defaultProps = {
  VERIFIED: false,
};
