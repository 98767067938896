import isObject from 'lodash.isobject';

const getDefaultKeys = (menuConfig = { top: [], bottom: [] }, { pathname }) => {
  if (
    !isObject(menuConfig) ||
    !menuConfig.hasOwnProperty('top') ||
    !menuConfig.hasOwnProperty('bottom')
  ) {
    return {
      defaultOpenKeys: [],
      defaultSelectedKeys: [],
    };
  }

  const items = [...menuConfig?.top, ...menuConfig?.bottom];
  let select = [];
  const matchPath = (it) => it?.path && pathname.includes(it?.path);

  const checkActive = (items) => {
    items.forEach((item) => {
      if ('subItems' in item) {
        checkActive(item?.subItems);
      } else {
        if (matchPath(item)) {
          select.push(item);
        }
      }
    });
  };

  checkActive(items);

  function findParents(items, targetItem, path = []) {
    for (const item of items) {
      const currentPath = [...path, item];
      if (item?.name === targetItem?.name && item?.path === targetItem?.path) {
        return path;
      }
      if (item?.subItems) {
        const result = findParents(item?.subItems, targetItem, currentPath);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const arr = select.sort((a, b) => {
    const aIndex = pathname.lastIndexOf(a?.path);
    const bIndex = pathname.lastIndexOf(b?.path);
    return aIndex - bIndex;
  });

  const activeMenu = arr[0];

  const open = findParents(items, activeMenu);
  return {
    defaultOpenKeys: open && open.map((item) => `${item?.name} - ${item?.path ? item?.path : ''}`),
    defaultSelectedKeys: activeMenu
      ? [`${activeMenu?.name} - ${activeMenu?.path ? activeMenu?.path : ''}`]
      : [],
  };
};

export default getDefaultKeys;
