import isRequiredError from './required';
import lengthError from './length';
import isNumericError from './isNumeric';
import isEmailError from './isEmail';
import isUrlError from './isUrl';
import isPercentsError from './isPercents';
import isPhoneError from './isPhone';
import isPasswordError from './password';
import sameAsError from './sameAs';
import emptySetError from './emptySet';
import addonError from './addon';
import isPostalCodeError from './isPostalCode';
import isOwnershipStakeError from './isOwnershipStake';
import isCurrencyError from './isCurrency';
import isCryptoCurrencyError from './isCryptoCurrency';
import isAlphaError from './isAlpha';
import isCodeError from './isCode';
import isDisableNumberError from './isDisableNumber';
import isAlphaAndNumbersError from './isAlphaAndNumbers';
import isCityError from './isCity';

export default {
  isRequiredError,
  lengthError,
  isEmailError,
  isUrlError,
  isPercentsError,
  isPhoneError,
  isPasswordError,
  sameAsError,
  emptySetError,
  addonError,
  isNumericError,
  isPostalCodeError,
  isOwnershipStakeError,
  isCurrencyError,
  isCryptoCurrencyError,
  isAlphaError,
  isCodeError,
  isAlphaAndNumbersError,
  isCityError,
  isDisableNumberError,
};
